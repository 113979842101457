import React from 'react';
import { useState, useEffect } from "react";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useForm } from "react-hook-form";
import Card from 'react-bootstrap/Card';
import { ErrorControl, ReactModal, TextControl } from '../../Components';
import { FnHttpService } from "../../Utilities";
import { GlobalSharedReduxFn } from '../../Context';
import { useDispatch } from 'react-redux';

const Configuration = () => {
    const [isModalOpen, setModalState] = useState(false);
    const { register, handleSubmit, watch, setValue, reset, control, formState: { errors } } = useForm();
    const { default: classNames } = require("classnames");
    const dispatch = useDispatch();
    // const url = 'https://localhost:44397/';
    let url = "https://evisa-dev-api.cloud7info.com/"

    const [configuration, setConfiguration] = useState([]);

    useEffect(() => {
        getConfigurationList()
    }, [])


    const getConfigurationList = () => {
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        axios.get(url + 'api/Config')
            .then(response => {
                dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                const data = response.data.data
                console.log('config', data)
                setConfiguration(data)
            })
    }

    const SubmitConfiguration = (data) => {
        data.imageFile = data.imageFile[0];
        let formData = new FormData();
        FnHttpService.toFormData(formData, data);
        // debugger
        if (data.id) {

            axios.put(url + 'api/Config/' + data.id, formData).then((response) => {

                if (response.data.isSuccess) {
                    console.log("update", response);
                    getConfigurationList();
                    setModalState(false);

                }
            }
            )

        }
        else {

            axios.post(url + 'api/Config', formData).then((response) => {
                debugger
                console.log(response.data.data)
                if (response.data.isSuccess) {
                    console.log("create", response);
                    getConfigurationList();
                    setModalState(false);
                    reset({});
                }

            })


        }
    }
    const deleteConfiguration = (id) => {

        axios.delete(url + 'api/Config/' + id).then((response) => {

            if (response.data.isSuccess) {
                console.log("delete", response);
                getConfigurationList();
                setModalState(false);

            }
        }
        )

    }


    const updateConfiguration = (configData) => {
        // debugger;
        reset(configData);
        setModalState(true);
    }

    return (<>

        <>
            <div className="d-flex">
                {(isModalOpen) && <ReactModal size={'md'} onModalClose={() => { setModalState(false) }}>

                    <form onSubmit={handleSubmit(SubmitConfiguration)}>
                        <Card>
                            <Card.Header><h3>Configuration</h3></Card.Header>
                            <Card.Body>
                                <div className="px-4 border-rounded border-dark">
                                    <TextControl name="Configuration Key" formReg={register("itemKey", {
                                        required: "Required", maxLength: {
                                            value: 100,
                                            message: "Max Length is only 100 char."
                                        },
                                    })} lblText="Configuration Key" errorObj={errors}></TextControl>

                                    <TextControl name="Key Value" formReg={register("itemKeyValue", {
                                        required: "Required", maxLength: {
                                            value: 200,
                                            message: "Max Length is only 200 char."
                                        },
                                    })} lblText="Key Value" errorObj={errors}></TextControl>

                                    <TextControl name="Configuration Value" formReg={register("itemValue", {
                                        required: "Required", maxLength: {
                                            value: 2000,
                                            message: "Max Length is only 2000 char."
                                        },
                                    })} lblText="Configuration Value" errorObj={errors}></TextControl>

                                    <ErrorControl name={''} errorObj={errors}></ErrorControl>
                                    <input
                                        className="form-control-file py-2"
                                        type="file"
                                        {...register('imageFile', {
                                            validate: {
                                                maxSize: (file) => {
                                                    if (!file[0]) return true;
                                                    return file[0]?.size <= 5 * 1024 * 1024 || "File size should be less than 5MB";
                                                },
                                            },
                                        })}
                                    />
                                </div>

                            </Card.Body>

                            <div className='card-footer d-flex'>
                                <Button type="submit" className='ms-auto bg-dark border-0'>Save</Button>
                            </div>


                        </Card>
                    </form>

                </ReactModal>
                }

                <Button type="button" className="bg-dark p-2 my-1 mb-2 ms-auto text-white rounded border-0" onClick={() => { setModalState(true) }}>Add Configuration</Button>
            </div>
            <div className='col-12 mx-2'>
                <Table striped bordered hover className='text-center'>
                    <thead >
                        <tr>
                            <th>S.No.</th>
                            <th></th>
                            <th>Key</th>
                            <th>Key Value</th>
                            <th>Value</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {configuration.map((x, index) => {
                            return (
                                <tr key={index}>

                                    <td>{index + 1}</td>
                                    <td>
                                        {x.imagePath ? <img className="" alt='No Image'
                                            src={url + x.imagePath}
                                            // src={getImageUrl(x.id)}
                                            style={{ height: '70px', width: '90px' }} /> : "No Image"}
                                    </td>
                                    <td>{x.itemKey}</td>
                                    <td>{x.itemKeyValue}</td>
                                    <td>{x.itemValue}</td>
                                    <td><Button type="button" variant="outline-danger" onClick={() => deleteConfiguration(x.id)} >Delete</Button>
                                        <Button type="button" variant="outline-warning" className='mt-2' onClick={() => updateConfiguration(x)} >Edit</Button></td>

                                </tr>

                            );
                        }
                        )
                        }
                    </tbody>
                </Table>
            </div>
        </>

    </>
    );
};

export default Configuration;