import { useNavigate } from "react-router-dom";
const Servicecard = ({ service, title, details, imgUrl, height = '280px', width = '370px' }) => {
    const navigate = useNavigate();
    return (<>
        <div className="card col-lg-4 col-md-6 col-sm-12 p-0 my-2 mx-3 text-white rounded" style={{ backgroundImage: `url(${imgUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", width: width, height: height }}
            onClick={() => navigate(`/services/${service.id}`, { state: service })}
        >
            <div className="card-img-overlay p-0" style={{
                backgroundColor: '#1c2539c2',
                opacity: '100%',
                textAlign: "start",
                marginTop: '130px',
                borderRadius: '3px'
            }}>
                <div className="card-title p-0 m-0  ">
                    <h4 className="text-white fw-bolder ms-3 my-1">{title}</h4>
                    <p className="mx-3 p-0 my-0 card-text text-wrap" style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '70px' }}>{details}</p>
                </div>


            </div>
        </div>

    </>)
}
export default Servicecard;