import { useLocation } from 'react-router-dom';
import { useState } from "react";
import img1 from '../Assets/tourist-visa-bg.png';
import img2 from '../Assets/official_visa_bg.png';
import img3 from '../Assets/transit-visa-bg.png';
import img4 from '../Assets/diplomatic-visa-bg.png';
import img5 from '../Assets/courtesy-visa-bg.png';
import img6 from '../Assets/wp13411102-visa-exchange-card-wallpapers.jpg';
import img7 from '../Assets/wp5981961-minimal-plane-black-wallpapers.jpg';
import img8 from '../Assets/wp4144066-visa-wallpapers.jpg';
import { ButtonPrimary } from '../Components';
import HTMLRenderComp from '../Components/HTMLRenderComp';
const ServicePage = () => {
    const location = useLocation();
    const serviceData = location.state;
    // const url = "https://localhost:44397/";
    const url = "https://evisa-dev-api.cloud7info.com/"
    // const [htmlContent, setHtmlContent] = useState(serviceData.description);
    const getImageUrl = (id) => {
        switch (id) {
            case 75:
                return img1;
            case 76:
                return img2;
            case 77:
                return img3;
            case 78:
                return img4;
            case 79:
                return img5;
            case 80:
                return img6;
            case 81:
                return img7;
            case 82:
                return img8;
            default:
                return img1;
        }

    }

    return (<>
        {/* {console.log(htmlContent, 'html')} */}
        <div className="row conatainer-fluid mx-0 px-0 bg-white">
            {/* <img src={serviceData.imagePath} style={{ width: '100%', height: '400px', display: 'flex', }}></img> */}

            <div className='col-lg-4 col-md-6 col-sm-12 border p-3'>
                <img
                    src={`${url}${serviceData?.imagePath}`}
                    // src={getImageUrl(serviceData.id)} {`${url}${service?.imagePath}`}
                    style={{ width: '100%', maxHeight: '400px', display: 'flex', }}></img>
            </div>
            <div className='col-lg-8 col-md-6 col-sm-12 border'>
                <h1 className='my-3'>{serviceData?.displayName}</h1>
                <HTMLRenderComp htmlContent={serviceData?.description} />

                <div className='d-flex my-5'>
                    <button type="button" className="btn bg-primary text-white ms-auto mt-auto me-3 px-5" >Apply Now</button>
                </div>
            </div>
        </div >

    </>);
}
export default ServicePage;