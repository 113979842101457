import { WebClientAbs } from "../Utilities";
import { WebConfig } from "../WebConfig";

export class WebClientServerApi extends WebClientAbs {
    constructor(apiController) {
        super(process.env.REACT_APP_EMS_MASTER_API, apiController, process.env.REACT_APP_RESPONSE_TYPE_VERSION, true);
    }
}

export class IdentityWebClientServerApi extends WebClientAbs {
    constructor() {
        super(process.env.REACT_APP_IDENTITY_SERVER_API, WebConfig.quizitute.apiEndPoints.authentication.controller, process.env.REACT_APP_RESPONSE_TYPE_VERSION, true);
    }
}
export class GlobalWebClientServerApi extends WebClientAbs {
    constructor(apiController) {
        super(process.env.REACT_APP_IDENTITY_SERVER_API, apiController, process.env.REACT_APP_RESPONSE_TYPE_VERSION, true);
    }
}

export class AdminWebClientServerApi extends WebClientAbs {
    constructor(apiController) {
        super(process.env.REACT_APP_ADMINISTRATOR_API, apiController, process.env.REACT_APP_RESPONSE_TYPE_VERSION, true);
    }
}

export class TempWebClientServerApi extends WebClientAbs {
    constructor(apiController) {
        super(process.env.REACT_APP_Temp_API, apiController, process.env.REACT_APP_RESPONSE_TYPE_VERSION, true);
    }
}

