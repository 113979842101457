import { Link } from "react-router-dom";

const AboutCard2 = ({ image, title, link }) => {
    return (<>
        <div className="col-lg-4 col-md-6 col-sm-12 my-2  p-0 ">
            <div className="card shadow bg-white py-3 px-5 d-flex text-center mx-2 ">

                <img src={image} className="mx-auto mt-2 mb-3" style={{ width: '120px', height: '120px' }} alt="" />
                <h4 className="my-2">{title}</h4>
                <Link className="my-2" to={link}>Lern More</Link>
            </div>

        </div>
    </>)
}
export default AboutCard2;