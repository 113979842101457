import React from 'react';
import { Navigation } from 'react-minimal-side-navigation';
import { useNavigate } from 'react-router-dom';
import { LuUserCog } from 'react-icons/lu';
import { MdOutlineMiscellaneousServices } from 'react-icons/md';
import { FcDataConfiguration } from 'react-icons/fc';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import { useSelector } from 'react-redux';
import { WebConfig } from '../WebConfig';

const LeftSidebarComp = () => {
    const history = useNavigate();
    const showSideBar = useSelector(state => state.GlobalSharedRedux.expandSidebar);

    const handleSelect = ({ itemId }) => {
        history(itemId);
    };

    if (!showSideBar) {
        return null;
    }

    return (
        <div className='p-2 bg-white'>
            <Navigation
                activeItemId="/admin/user-manager"
                onSelect={handleSelect}
                items={[
                    {
                        title: 'User Manager',
                        itemId: '/admin/user-manager',
                        elemBefore: () => <LuUserCog />,
                    },
                    {
                        title: 'Service Manager',
                        itemId: '/admin/service-manager',
                        elemBefore: () => <MdOutlineMiscellaneousServices />,
                    },
                    {
                        title: 'Configuration',
                        itemId: '/admin/configuration',
                        elemBefore: () => <FcDataConfiguration />,
                    },
                ]}
            />
        </div>
    );
};

export default LeftSidebarComp;
