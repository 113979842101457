import { useState, useEffect, useCallback } from "react";
import axios from 'axios'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useForm, Controller } from "react-hook-form";
import { ErrorControl, ReactModal, TextControl } from "../Components";
import Card from 'react-bootstrap/Card';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FnHttpService, FnNotificationUI } from "../Utilities";
import { useSelector } from 'react-redux';
import { WebConfig } from "../WebConfig";
import ImageCropper from "./ImageCropper";
import ImageCropper2 from "./ImageCropper2";
import { GlobalSharedReduxFn } from '../Context';
import { useDispatch } from 'react-redux';
import { EditorState, convertToRaw, Draft, ContentState } from 'draft-js';

const ServiceManager = () => {
    // const url = "https://localhost:44397/";
    const dispatch = useDispatch();
    const userToken = useSelector(state => state[WebConfig.contextKeys.currentUserRedux].authToken);
    const url = "https://evisa-dev-api.cloud7info.com/"



    const [isModalOpen, setModalState] = useState(false);
    const { register, handleSubmit, watch, setValue, getValues, reset, control, formState: { errors } } = useForm();
    const { default: classNames } = require("classnames");

    const [servicesList, setServicesList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        getServicesList()
    }, [])

    const onEditorStateChange = (state) => {
        setEditorState(state);
        const textEditor = state.getCurrentContent();
        setValue("description", textEditor.getPlainText());
    };

    const getServicesList = () => {
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        try {
            axios.get(url + 'api/ourservices')
                .then(response => {
                    dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                    const data = response.data
                    console.log('get service', data)
                    setServicesList(data)
                })

        }
        catch (error) {
            dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
            console.error('Error:', error.message);
        }

    }

    const openFormEditor = (item) => {
        console.log(item);
        if (item.imagePath) {
            setImageUrl(url + item?.imagePath);
        }
        if (item.description) {
            setEditorState(EditorState.createWithContent(ContentState.createFromText(item.description)))
        }
        else {
            setEditorState(EditorState.createEmpty())
        }
        reset(item || {});
        setModalState(true);

    }

    const closeModal = () => {
        reset({});
        setIsEdit(false);
        setModalState(false);
        setImageUrl(null);
    }

    const SubmitService = (data) => {
        debugger;
        console.log(data, "service")
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        data.imageFile = data.imageFile;
        let formData = new FormData();
        FnHttpService.toFormData(formData, data);
        if (data.id) {
            try {
                axios.put(url + 'api/ourservices/' + data.id,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`
                        }
                    }).then((response) => {
                        if (response.data.data) {
                            dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                            console.log("update", response);
                            setModalState(false);
                            getServicesList();
                        }
                    }
                    )
            }
            catch (error) {
                dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                console.error('Error:', error.message);
            }

        }

        else {
            try {
                axios.post(url + 'api/ourservices', formData).then((response) => {
                    // debugger
                    console.log(response.data.data)
                    if (response.data.isSuccess) {
                        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                        console.log("create", response);
                        getServicesList();
                        setModalState(false);
                        reset({});
                    }

                })

            }
            catch (error) {
                dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                console.error('Error:', error.message);
            }

        }
    }
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileURL = URL.createObjectURL(file);
            setImageUrl(fileURL);
            setValue('imageFile', file);
        }
    };
    const updateService = (service) => {
        console.log(service, 'editservice');
        setIsEdit(true);
        openFormEditor(service);
    }

    const deleteService = (service) => {
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        try {
            axios.put(url + 'api/ourservices/deleteService/' + service.id,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }).then((response) => {
                    if (response) {
                        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                        console.log("delete", response);
                        // setModalState(false);
                        getServicesList();
                    }
                }
                )
        }
        catch (error) {
            dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
            console.error('Error:', error.message);
        }
    }



    return (
        <>

            <>
                <div className="d-flex">

                    {(isModalOpen) && <ReactModal onModalClose={() => { closeModal() }}>
                        <form onSubmit={handleSubmit(SubmitService)}>
                            <Card>
                                <Card.Header className=""><h3>{isEdit ? "Update" : "Create"} Service</h3></Card.Header>
                                <Card.Body>
                                    <div className="px-4 border-rounded border-dark">
                                        <TextControl lblText="Display Name" formReg={register("displayName", {
                                            required: "Required", maxLength: {
                                                value: 100,
                                                message: "Max Length is only 100 char."
                                            },
                                        })} errorObj={errors} name={"displayName"}></TextControl>

                                        <TextControl lblText="Short Description" formReg={register("shortDescription", {
                                            required: "Required", maxLength: {
                                                value: 250,
                                                message: "Max Length is only 250 char."
                                            },
                                        })} errorObj={errors} name={"shortDescription"}></TextControl>

                                        {/* <TextControl lblText="Description" formReg={register("description", {
                                            required: "Required",
                                        })} errorObj={errors} name={"description"}></TextControl> */}

                                        <div className="border my-2">
                                            <Controller
                                                name="description"
                                                control={control}
                                                // defaultValue={false}
                                                rules={{ required: 'This is required' }}
                                                render={({ field }) => (
                                                    <Editor
                                                        editorState={editorState}
                                                        wrapperClassName="demo-wrapper"
                                                        editorClassName="demo-editor"
                                                        onEditorStateChange={onEditorStateChange}
                                                    />
                                                )}
                                            />
                                            {errors.description && <p className="text-danger">{errors.Description.message}</p>}
                                            {/* <div>{editorState.getCurrentContent()}</div> */}
                                        </div>


                                        {/* <TextControl lblText={'Image File'} type="file"
                                            formReg={register('imageFile')}
                                            errorObj={errors} name={"imageFile"} >

                                        </TextControl> */}
                                        <label className="my-1">
                                            Image File
                                        </label>
                                        <div className="row">
                                            {/* {console.log(imageUrl, 'imag')} */}
                                            {imageUrl &&
                                                <div className="row">
                                                    <div className="col-4" >
                                                        <img src={imageUrl} style={{ height: "150px", width: "200px" }} />
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-left align-items-center">
                                                        <div className="input-group mb-3">
                                                            <label className="input-group-text" >Change Image</label>
                                                            <input
                                                                className="form-control-file py-2 d-none"
                                                                id="inputGroupFile01"
                                                                type="file"
                                                                {...register('imageFile', {
                                                                    validate: {
                                                                        maxSize: (file) => {
                                                                            if (!file[0]) return true;
                                                                            return file[0]?.size <= 5 * 1024 * 1024 || "File size should be less than 5MB";
                                                                        },
                                                                    },
                                                                })}
                                                                onChange={handleImageChange}
                                                            />
                                                        </div>
                                                        {/* <button type="button" className="btn btn-dark">Change Image
                                                    </button> */}
                                                    </div>
                                                </div>
                                            }
                                            {!imageUrl && <div className="col-4" >
                                                <input
                                                    className="form-control-file py-2"
                                                    type="file"
                                                    {...register('imageFile', {
                                                        validate: {
                                                            maxSize: (file) => {
                                                                if (!file[0]) return true;
                                                                return file[0]?.size <= 5 * 1024 * 1024 || "File size should be less than 5MB";
                                                            },
                                                        },
                                                    })}
                                                    onChange={handleImageChange}
                                                />
                                            </div>}
                                            {/* <Controller
                                                name="image"
                                                control={control}
                                                render={({ field }) => (
                                                    <ImageCropper2 setValue={setValue} initialImageUrl={img1} />
                                                )}
                                            /> */}
                                            {/* <ImageCropper2 setValue={setValue} initialImageUrl={img1} /> */}

                                        </div>

                                    </div>


                                </Card.Body>
                                <Card.Footer className="d-flex">
                                    <Button type="submit" className="ms-auto border bg-dark btn-lg">Save</Button>
                                </Card.Footer>

                            </Card>
                        </form>

                    </ReactModal>}
                    <Button type="button" className="bg-dark border-0 my-2 mx-2 ms-auto" onClick={() => { openFormEditor({}) }}>Create Service</Button>
                    {/* <Button type='button' className="btn btn-dark " onClick={() => FnNotificationUI.notifySuccess("Success")}>notify</Button> */}
                </div>


                <Table striped bordered hover className='text-center'>
                    <thead >
                        <tr className="">
                            <th></th>
                            <th>Sr Number</th>
                            <th>Display Name</th>
                            <th>Short Description</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {servicesList.map((x, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <img className=""
                                            src={url + x.imagePath}
                                            // src={getImageUrl(x.id)}
                                            style={{ height: '70px', width: '90px' }} />
                                    </td>
                                    <td>{index + 1}</td>
                                    <td>{x.displayName}</td>
                                    <td>{x.shortDescription}</td>
                                    <td>{(x?.status === 0) ? "Active" : (x?.status === 2) ? "Inactive" : "Unapproved"}</td>
                                    <td><div className="d-flex">
                                        <Button type="button" variant="outline-danger" onClick={() => deleteService(x)}>Delete</Button>
                                        <Button type="button" variant="outline-warning" className='ms-2' onClick={() => updateService(x)}>Edit</Button>
                                    </div></td>
                                </tr>

                            );
                        }
                        )
                        }
                    </tbody>
                </Table>
            </>


        </>
    )
}
export default ServiceManager;