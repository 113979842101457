import Servicecard from './Servicecard';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { TbSquareRoundedArrowRight } from "react-icons/tb";
import ViewMore from "./ServiceList";
import { Link } from 'react-router-dom';
import img1 from '../Assets/tourist-visa-bg.png';
import img2 from '../Assets/official_visa_bg.png';
import img3 from '../Assets/transit-visa-bg.png';
import img4 from '../Assets/diplomatic-visa-bg.png';
import img5 from '../Assets/courtesy-visa-bg.png';
import { WebConfig } from "../WebConfig";
import { GlobalSharedReduxFn } from '../Context';
import { useDispatch } from 'react-redux';

const Visaservices = () => {
    const dispatch = useDispatch();
    // const url = "https://localhost:44397/";
    const url = "https://evisa-dev-api.cloud7info.com/"
    const [servicesList, setServicesList] = useState([]);

    const getImageUrl = (id) => {
        switch (id) {
            case 75:
                return img1;
            case 76:
                return img2;
            case 77:
                return img3;
            case 78:
                return img4;
            case 79:
                return img5;
            default:
                return img1;
        }

    }


    useEffect(() => {
        getServicesList()
    }, [])


    const getServicesList = () => {
        // debugger;
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        try {
            axios.get(url + 'api/ourservices')
                .then(response => {
                    if (response) {
                        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                    }
                    const data = response.data
                    console.log('getservices List', data)
                    setServicesList(data.filter(x => x.status === 0));
                })

        }
        catch (error) {
            dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
            console.error('Error:', error.message);
        }

    }

    return (<>
        <section className="col-lg-12 col-md-12 col-sm-12 bg-white" >
            <div className="row container-fluid  ">
                <div className="col-lg-12 col-md-12 com-sm-12" style={{ backgroundImage: `url(${require('../Assets/passport-bg.png')})`, backgroundRepeat: "no-repeat", backgroundPositionX: '88%', paddingRight: "20px", backgroundPositionY: '12%' }}>
                    <div className="fs-1 ms-4 py-3 d-flex justify-content-left align-items-center">Over<span className="text-secondary mx-2">Visa</span> Services</div>

                    <div className="row my-2 ms-lg-3 ms-sm-3 px-0">
                        {servicesList.slice(0, 2).map((service, index) => (
                            < Servicecard
                                key={index}
                                service={service}
                                title={service?.displayName}
                                details={service?.shortDescription}
                                // imgUrl={getImageUrl(service?.id)}
                                imgUrl={`${url}${service?.imagePath}`}
                            />

                        ))
                        }

                    </div>
                    <div className="row my-2 ms-lg-3 ms-sm-3">
                        <div className="col-lg-1 col-md-12 col-sm-12 m-0 p-0"></div>
                        {servicesList.slice(2, 5).map((service, index) => (
                            // <div className="col-lg-3 col-md-3 col-sm-3" key={index}>
                            <Servicecard
                                key={index}
                                service={service}
                                title={service?.displayName}
                                details={service?.shortDescription}
                                // imgUrl={getImageUrl(service?.id)}
                                imgUrl={`${url}${service?.imagePath}`}
                            />
                            // </div>
                        ))}
                         <div className='col-lg-1 col-md-6 col-sm-6 row my-2 text-center m-0 pe-4'>
                            <div className='card me-3 p-0 border'>
                                <label className='col mt-5 pt-5 '><h4>View more</h4></label>
                                <Link to="/allServices"><button className='col border-0'  ><TbSquareRoundedArrowRight className='mb-5' color="#09af20" size={54} /></button></Link>
                            </div>


                        </div>
                    </div>

                </div>

            </div>

        </section>
    </>)
}
export default Visaservices;