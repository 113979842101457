import { WebConfig } from "../WebConfig";
import { IdentityWebClientServerApi } from "../ServicesShared/WebClientServers";
import { FnHttpService } from "../Utilities";

export class AuthenticationService extends IdentityWebClientServerApi {
    constructor(apiController) {

        super(apiController);
    }

    SignUpAsync = (postData) => {
        let formData = new FormData();
        FnHttpService.toFormData(formData, postData);
        return this.httpClient.post(WebConfig.eVisa.apiEndPoints.authentication.methods.signUp, formData);
    }
    SignInAsync = (postData) => {
        let formData = new FormData();
        FnHttpService.toFormData(formData, postData);
        return this.httpClient.post(WebConfig.eVisa.apiEndPoints.authentication.methods.signIn, formData);
    }

}