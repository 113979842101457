import photo1 from '../Assets/Hubspotters.webp';
import photo2 from '../Assets/grow-better.webp';
import photo3 from '../Assets/Rangan-Halligan.webp';
import pCard2 from '../Assets/2022_Design_Icon_ThreePortraits-2.svg';
import pCard1 from '../Assets/2023_Contacts_DataBase2.svg';
import pCard3 from '../Assets/2023_World_Collaboration.svg';
import AboutCard from '../Components/AboutCard';
import AboutCard2 from '../Components/AboutCard2';
import Configuration from './Admin/Configuration';
import { useState, useEffect } from "react";
import axios from 'axios';



const AboutUs = () => {

    let url = "https://evisa-dev-api.cloud7info.com/"
    const [configuration, setConfiguration] = useState([]);

    const getConfigurationList = () => {
        axios.get(url + 'api/Config')
            .then(response => {
                const data = response.data.data
                console.log('config in abtus', data)
                setConfiguration(data)
            })
    }
    useEffect(() => {
        getConfigurationList();
    }, [])

    return (<>

        <div>
            <AboutCard text1={configuration[8]?.itemValue} title={configuration[8]?.itemKeyValue} titletagclass='h1' image={`${url}${configuration[8]?.imagePath}`} isRight={false} isHeader={true} />
            <AboutCard text1={configuration[9]?.itemValue} title={configuration[9]?.itemKeyValue} image={`${url}${configuration[9]?.imagePath}`} isRight={true} />
            <AboutCard text1={configuration[10]?.itemValue}
                       text2={configuration[11]?.itemValue} title={configuration[10]?.itemKeyValue} image={`${url}${configuration[10]?.imagePath}`} isRight={false} />

        </div>
        {/* <div className="row container-fluid bg-light text-center py-3">
            <h2 className='my-5'>Our Services By the Numbers</h2>
            <div className="row container-fluid  pb-5 mb-2 d-flex justify-content-center">
                <div className='col-lg-10 mx-auto col-md-10 col-sm-10 row d-flex justify-content-center justify-content-sm-center'>
                    <AboutCard2 image={pCard3} title="12 Global Offices" link="/" />
                    <AboutCard2 image={pCard2} title="7,600+ Employees" link="/" />
                    <AboutCard2 image={pCard1} title="205,000+ Customers" link="/" />
                </div>

            </div>
        </div> */}
    </>)
}
export default AboutUs;