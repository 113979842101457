import { TfiHeadphoneAlt } from "react-icons/tfi";
import { FaRegAddressCard } from "react-icons/fa";
import { FaClock } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';
import logo1 from '../Assets/Logo_1_2_trns.png';
import logo2 from '../Assets/logo2_trns.png';
import { useEffect, useState } from "react";
import { ReactModal } from "./Modal";
import LoginPage from "../Pages/AuthPage";
import { useSelector } from 'react-redux';
import { WebConfig } from "../WebConfig";
import axios from 'axios';
import { GlobalSharedReduxFn } from '../Context';
import { useDispatch } from 'react-redux';
import { GlobalCurrentUserReduxFn } from "../Context"
import { ButtonDark } from "./Buttons";
import { BiUserCircle } from "react-icons/bi";
import { FnHttpService } from "../Utilities";
import './Header.scss';
import { FnNotificationUI } from "../Utilities";
import { useNavigate } from "react-router-dom";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareTwitter } from "react-icons/fa6";
import { AiFillGooglePlusSquare } from "react-icons/ai";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaBars } from "react-icons/fa6";


const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isModalOpen, setModalState] = useState(false);
    const userToken = useSelector(state => state[WebConfig.contextKeys.currentUserRedux].authToken);
    const user = useSelector(state => state[WebConfig.contextKeys.currentUserRedux].user);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [services, setServices] = useState([]);
    const [configuration, setConfiguration] = useState([]);
    // const url = 'https://localhost:44397/';
    let url = "https://evisa-dev-api.cloud7info.com/"

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };
    const closeModal = () => {
        setModalState(false);

    }
    const isDashboard = location.pathname.startsWith('/admin');
    const showSideBar = useSelector(state => state[WebConfig.contextKeys.globalSharedRedux].expandSidebar);

    const handleSignOut = () => {
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        setIsDropdownOpen(false); //[WebConfig.contextKeys.globalSharedRedux].showPageLoader
        dispatch(GlobalCurrentUserReduxFn.authTokenSet(undefined));
        dispatch(GlobalCurrentUserReduxFn.setUser(null));
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
        // FnNotificationUI.notifySuccess("Success", "Success")
    }
    const getUserDetails = async (resp) => {
        debugger;
        try {
            console.log(`Bearer ${resp}`);
            const response = await axios.get('https://evisa-dev-api.cloud7info.com/api/usermanager/me', {
                headers: {
                    Authorization: `Bearer ${resp}`
                }
            });
            if (response) {
                dispatch(GlobalCurrentUserReduxFn.setUser(response.data.data));
            }
            console.log(response.data.data, "user")
            return response.data;
        } catch (error) {
            console.error('Error fetching user details:', error);
            return null;
        }

    }
    // const getServices = () => {
    //     axios.get('https://evisa-dev-api.cloud7info.com/api/ourservices')
    //         .then(response => {
    //             const data = response.data;
    //             setServices(data.filter(x => x.status == 0));
    //             console.log('Fservices', services);
    //         })
    // }
    const getServices = () => {
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        try {
            axios.get('https://evisa-dev-api.cloud7info.com/api/ourservices')
                .then(response => {
                    dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                    const data = response.data
                    console.log('get service', data)
                    setServices(data.filter(x => x.status == 0));
                })

        }
        catch (error) {
            dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
            console.error('Error:', error.message);
        }

    }
    const getConfigurationList = () => {
        axios.get(url + 'api/Config')
            .then(response => {
                const data = response.data.data
                console.log('config in footer', data)
                setConfiguration(data)
            })
    }

    useEffect(() => {
        getServices();
        getConfigurationList();
    }, [])

    useEffect(() => {
        debugger;
        if (userToken) {
            getUserDetails(userToken);
        }

    }, [userToken])


    return (
        <>
            <div className="row container-fluid px-0 mx-0">
                <section className="col-lg-12 col-md-12 col-sm-12 py-2 bg-dark d-flex justify-content-center">
                    <div className="container-fluid m-0 p-0 bg-dark">
                        <div className="row container-fluid m-0 p-0 d-flex ">
                            <div className="col-lg-6 col-md-6 px-1 mx-0 col-sm-12 d-flex text-white justify-content-lg-start justify-content-sm-start">
                                <div className="d-flex justify-content-center align-items-center">
                                    <TfiHeadphoneAlt color="#09af20" size={20} />
                                    <span className="ps-1 "> Help Desk {configuration[0]?.itemValue} /{configuration[1]?.itemValue}</span>

                                </div>
                            </div>
                            <div className="row col-lg-6 px-0 mx-0 col-md-6 col-sm-12 text-white d-flex align-items-center justify-content-sm-start justify-content-md-end justify-content-lg-end ">
                                <div className="col-lg-8 col-md-6 col-sm-12 d-flex justify-content-sm-start justify-content-md-end justify-content-lg-end align-items-center px-2 " style={{ borderRight: "1px solid grey", height: "40px" }}>
                                    <FaClock color="#09af20" size={20} />
                                    <span className=" ps-1"> {configuration[7]?.itemValue}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 d-flex px-2 align-items-center justify-content-sm-start justify-content-md-end justify-content-lg-end">
                                    <span className="me-1"> Follow Us :</span>
                                    <Link to="https://www.facebook.com/Bedcoconsult/"><FaSquareFacebook color="white" size={30} /></Link>
                                    <Link className="ms-2" to="https://twitter.com/i/flow/login?redirect_after_login=%2FBedcoConsult"><FaSquareTwitter color="white" size={30} /></Link>
                                    <Link className="ms-2" to="/"><AiFillGooglePlusSquare color="white" size={30} /></Link>
                                    <Link className="ms-2" to="https://www.instagram.com/bedcoconsult/"><FaSquareInstagram color="white" size={30} /></Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="col-lg-12 col-sm-12 col-md-12 bg-white">
                    <nav className="navbar navbar-expand-lg">
                        <div className="container-fluid d-flex ">
                            <Link className="navbar-brand" to="/">
                                <img src={logo1} alt="" style={{ height: "3rem" }} />
                                <img src={logo2} style={{ width: "8rem" }} />
                            </Link>

                            {isDashboard &&
                                <div className="me-auto ">
                                    <button className=" mb-1 border-0 bg-white" onClick={() => dispatch(GlobalSharedReduxFn.sidebarToggle(showSideBar))}
                                    >

                                        <FaBars size={20} /></button></div>

                            }
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={toggleNav}
                                aria-controls="navbarNav"
                                aria-expanded={isNavOpen ? "true" : "false"}
                                aria-label="Toggle navigation"
                            >
                                <i className="fa-solid fa-bars-staggered navbar-toggler-icon"></i>
                            </button>

                            <div className={`collapse navbar-collapse justify-content-end ${isNavOpen ? 'show' : ''}`} id="navbarNav">
                                <ul className="navbar-nav manu-navbar-nav ">
                                    <li className="nav-item me-3 ">
                                        <Link className="nav-link active" to="/"> Home</Link>
                                    </li>

                                    <li className="nav-item me-3 dropdown">
                                        <div className="nav-link dropdown-toggle text-dark" onClick={() => navigate('/allServices')}> Our Services</div>
                                        <ul className="dropdown-menu">
                                            {services.map(service => (
                                                <li key={service.id}
                                                    onClick={() => navigate(`/services/${service.id}`, { state: service })}
                                                >
                                                    <Link className="dropdown-item">{service?.displayName}</Link></li>
                                            ))}
                                        </ul>
                                    </li>
                                    <li className="nav-item me-3 ">
                                        <Link className="nav-link text-dark" to="/aboutus"> About Us</Link> {/* Update to point to the About Us page */}
                                    </li>
                                    <li className="nav-item"  >
                                        <Link className="nav-link text-dark" to="/contactus">  Contact Us</Link> {/* Update to point to the Contact Us page */}
                                    </li>
                                    <li className="nav-item me-3" style={{ borderRight: "1px solid grey", height: "40px" }}>
                                        <Link className="nav-link text-dark" to="https://bedcoconsultlimite.wixsite.com/bedco/blog"> FAQ</Link> {/* Update to point to the About Us page */}
                                    </li>

                                    <li className="nav-item ms-3 mt-0 pt-0">
                                        {userToken ? (
                                            <div className="dropdown">
                                                <ButtonDark className="dropdown-toggle" type="button" onClick={toggleDropdown}>
                                                    < BiUserCircle size={26} color="#09af20" /> {user?.email}
                                                </ButtonDark>
                                                <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
                                                    <li onClick={() => setIsDropdownOpen(false)}><Link className="dropdown-item" to="/userprofile">Profile</Link></li>
                                                    {user?.roleName !== 'User' && <li onClick={() => setIsDropdownOpen(false)}><Link className="dropdown-item" to="/admin">Dashboard</Link></li>}
                                                    <li><hr className="dropdown-divider" /></li>
                                                    <li><Link className="dropdown-item" to="/" onClick={handleSignOut} >Sign Out</Link></li>
                                                </ul>
                                            </div>
                                        ) : (
                                            <Link className="nav-link" to="/signin" onClick={() => { setModalState(true); }}>
                                                <FaRegAddressCard className="mx-1" color="#0e4b21" size={30} />
                                                Sign In/Up
                                            </Link>
                                        )}
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </nav >
                    {isModalOpen == true && <ReactModal onModalClose={closeModal}>
                        <LoginPage closeModal={closeModal} />
                    </ReactModal>
                    }
                </section >
            </div >
        </>
    )
}
export default Header;