import { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop';
import { ErrorControl, ReactModal, TextControl } from "../Components";

const ImageCropper = ({ imageUrl }) => {
    const [isModalOpen, setModalState] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        console.log(croppedArea, croppedAreaPixels)
    }

    // const showCroppedImage = useCallback(async () => {
    //     try {
    //       const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    //       setCroppedImage(croppedImage);
    //     } catch (e) {
    //       console.error(e);
    //     }
    //   }, [imageSrc, croppedAreaPixels]);

    const handleFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            let imageDataUrl = await readFile(file);
            setModalState(true);
            setImageSrc(imageDataUrl);
        }
    };

    const readFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => resolve(reader.result));
            reader.readAsDataURL(file);
        });
    };


    return (
        <div>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            {imageSrc && (
                <>
                    {/* {(isModalOpen) && <ReactModal onModalClose={() => { setModalState(false) }}> */}
                    <div className="crop-container">

                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={4 / 3}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                        />
                        <button>Save</button>
                    </div>
                    {/* </ReactModal>} */}
                    {croppedImage && (
                        <div>
                            <img src={croppedImage} alt="Cropped" />
                        </div>
                    )}
                </>
            )}
        </div>

    )
}
export default ImageCropper;