import React, { useState, useRef, useEffect } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useForm, Controller } from 'react-hook-form';

const ImageCropper2 = ({ setValue, initialImageUrl }) => {
    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const [crop, setCrop] = useState(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState(16 / 9);
    const [croppedImageFile, setCroppedImageFile] = useState(null);

    useEffect(() => {
        if (initialImageUrl) {
            setImgSrc(initialImageUrl);
        }
    }, [initialImageUrl]);

    function onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(null);
            const reader = new FileReader();
            reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    function onImageLoad(e) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
        return centerCrop(
            makeAspectCrop(
                { unit: '%', width: 90 },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        );
    }

    useEffect(() => {
        if (!completedCrop || !imgRef.current || !previewCanvasRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop = completedCrop;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        canvas.toBlob(blob => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            const file = new File([blob], 'cropped.jpg', { type: 'image/jpeg' });
            setCroppedImageFile(file);
            setValue('image', file);
        }, 'image/jpeg');
    }, [completedCrop, setValue]);

    return (
        <>
            <div className='row container-fluid'>
                <div className='col-lg-6 border border-secondary' style={{ height: "200px", width: "100%" }}>
                    <img
                        src={croppedImageFile ? URL.createObjectURL(croppedImageFile) : initialImageUrl}
                        alt="Cropped Image"
                        style={{ width: '100%', height: '100%' }}
                    />
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center Crop-Controls">
                    <input type="file" accept="image/*" onChange={onSelectFile} />
                </div>
            </div>
            {!!imgSrc && (
                <div className='d-flex align-items-center'>
                    <ReactCrop
                        crop={crop}
                        onChange={(_, percentCrop) => setCrop(percentCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        aspect={aspect}
                    >
                        <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                            onLoad={onImageLoad}
                        />
                    </ReactCrop>
                    <canvas ref={previewCanvasRef} style={{ display: 'none' }} />
                </div>
            )}
        </>
    );
};

export default ImageCropper2;
