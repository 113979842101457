import { useState } from "react";
import { Link } from 'react-router-dom';
import { ButtonDark, ButtonPrimary, TextControl } from "../Components";
import { set, useForm, watch, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthenticationService } from "../Services/AuthenticationService";
import { GlobalCurrentUserReduxFn } from "../Context"
import { Checkbox } from 'pretty-checkbox-react';
import { FnHttpService } from "../Utilities";
import { useDispatch } from 'react-redux';
import '@djthoms/pretty-checkbox';
import axios from 'axios';
import { FaCheckCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';
// import { WebConfig } from "../WebConfig";
import { WebConfig } from "../WebConfig";
import { GlobalSharedReduxFn } from '../Context';
// import { useDispatch } from 'react-redux';
// import { UserProfileService } from "../Services/userProfileService";


const AuthPage = ({ closeModal }) => {
    const dispatch = useDispatch();
    // const svcAuth = new AuthenticationService();
    // const userProfile = new UserProfileService();
    const navigate = useNavigate();
    const [screen, setScreen] = useState("signUp");
    const [agreeTerms, setAgreeTerms] = useState(false);
    // const userToken = useSelector(state => state[WebConfig.contextKeys.currentUserRedux].authToken);
    const { register, handleSubmit, watch, setValue, getValues, reset, control, formState: { errors } } = useForm();

    const handleCheckboxChange = () => {
        setAgreeTerms(!agreeTerms);
    };
    const onSignupClick = async (data) => {
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        console.log("hi", data);

        // FnHttpService.promiseHandlerSingle(svcAuth.SignUpAsync(data), props.oDispatch,
        //     (data) => {
        //         debugger;
        //         console.log('uLogin', data,);
        //         props.oDispatch(GlobalCurrentUserReduxFn.authTokenSet(data))
        //         // history(props.baseUrl + "private/");
        //         // getUserDetails();
        //     })
        let formData = new FormData();
        FnHttpService.toFormData(formData, data);

        try {
            const response = await axios.post('https://evisa-dev-api.cloud7info.com/api/auth/signup', formData);
            console.log('API response:', response.data);
            if (response) {
                dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                setScreen("signUpSuccess");
                // dispatch(GlobalCurrentUserReduxFn.authTokenSet(response.data.access_token));
                // closeModal();
                // navigate("/admin");
            }
        } catch (error) {
            dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
            console.error('Error:', error.message);
        }


    }
    const onSignInClick = async (data) => {
        debugger;
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        console.log(data, "login");
        let formData = new FormData();
        FnHttpService.toFormData(formData, data);

        try {
            const response = await axios.post('https://evisa-dev-api.cloud7info.com/api/auth/signin', formData);
            console.log('API response:', response.data);
            if (response) {
                dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                dispatch(GlobalCurrentUserReduxFn.authTokenSet(response.data.access_token));
                closeModal();
                navigate("/");
            }

        }
        catch (error) {
            dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
            console.error('Error:', error.message);
        }

    }
    const onResetPassword = (data) => {
        console.log(data, "email");
    }




    return (<>
        <div className="modal-body m-0 p-0">
            <div className="row container-fluid" >
                <div className="col-lg-6 pt-3 pb-5 shadow-lg bg-dark rounded-start text-white">
                    <h1 className="my-4 ms-3 p-3">INFOMATION</h1>
                    <p className="ms-3 p-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua.
                        Et molestie ac feugiat sed. Diam volutpat commodo.</p>
                    <p className="ms-3 p-3"><span className="fw-bold">Eu ultrices:</span> Vitae auctor eu augue ut.
                        Malesuada nunc vel risus commodo viverra. Praesent elementum facilisis leo vel.</p>
                    {/* <div className="text-center"><button type="button" className="bg-white btn-lg my-5 text-center">Have
                        an account</button></div> */}
                </div>

                {(screen == "signUp") &&
                    <div className="col-lg-6 py-5 rounded-end">
                        <form onSubmit={handleSubmit(onSignupClick)}>
                            <div className="my-1 ms-4">
                                <h3 className="my-3">Sign Up</h3>

                                <div className="me-4">
                                    <TextControl value={""} lblText={'Name'} placeholder={'enter your name'}
                                        formReg={register('name', {
                                            required: "Name is Required", maxLength: {
                                                value: 50,
                                                message: "Max Length is only 50 char."
                                            }, pattern: {
                                                value: /^[A-Za-z\s]+$/,
                                                message: "Only letters and spaces are allowed."
                                            }
                                        })}
                                        errorObj={errors} name={"name"} ></TextControl>

                                </div>

                                <div className="my-2 me-4">
                                    <TextControl value={"sdf@a.com"} lblText={'E-mail'}
                                        formReg={register('email', {
                                            required: "Email Required", maxLength: {
                                                value: 50,
                                                message: "Max Length is only 50 char."
                                            }, pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Not a valid Email"
                                            }
                                        })}
                                        errorObj={errors} name={"email"} ></TextControl>
                                    <div id="emailHelp" className="form-text">We'll never share your email with anyone else.
                                    </div>
                                </div>


                                <div className="me-4 mb-2">
                                    <TextControl value={"adf"} lblText={'Password'} type="password"
                                        formReg={register('password', {
                                            required: "Password Required", maxLength: {
                                                value: 20,
                                                message: "Max Length is only 20 char."
                                            }, minLength: {
                                                value: 3,
                                                message: "Atleast minimum 3 char. required"
                                            },
                                            // pattern: {
                                            //     value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                                            //     message: "Password should contain one Capital Letter, one Small Letter and One Special character"
                                            // }
                                        })}
                                        errorObj={errors} name={"Password"} >

                                    </TextControl>
                                </div>
                                <div className="me-4 mb-2">
                                    <TextControl
                                        value={""}
                                        lblText={'Confirm Password'}
                                        type="password"
                                        formReg={register('confirmPassword', {
                                            required: "Confirm Password Required",
                                            validate: {
                                                matchesPassword: (value) => {
                                                    const password = getValues('password');
                                                    return value === password || "Passwords do not match";
                                                }
                                            }
                                        })}
                                        errorObj={errors}
                                        name={"confirmPassword"}
                                    />

                                </div>

                            </div>

                            <div className="d-flex">
                                <div className="ms-4 my-2">
                                    <Controller
                                        name="agreeTerms"
                                        control={control}
                                        defaultValue={false}
                                        rules={{ required: 'You must agree to the Terms and Conditions for Login' }}
                                        render={({ field }) => (
                                            <Checkbox
                                                {...field}
                                                shape="curve"
                                                color="primary"
                                            >
                                                I agree to the Terms and Conditions
                                            </Checkbox>
                                        )}
                                    />
                                    {errors.agreeTerms && <p className="text-danger">{errors.agreeTerms.message}</p>}
                                </div>
                            </div>
                            <div className="d-flex my-2">
                                <ButtonDark type="submit" className="text-white mx-auto px-5">Ragister</ButtonDark>

                            </div>
                            <label className="d-flex">
                                <p className="mx-auto">Already have an account? <Link onClick={() => setScreen("signIn")}>Sign In</Link></p>
                            </label>
                        </form>
                    </div>}
                {(screen == 'signIn') &&

                    <div className="col-lg-6 py-5 rounded-end">
                        <form onSubmit={handleSubmit(onSignInClick)}>
                            <div className="my-1 ms-4">
                                <h3 className="my-3">Sign In</h3>

                                <div className="my-2 me-4">
                                    <TextControl value={"sdf@a.com"} lblText={'User Name'}
                                        formReg={register('email', {
                                            required: "Email Required", maxLength: {
                                                value: 50,
                                                message: "Max Length is only 50 char."
                                            }, pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Not a valid Email"
                                            }
                                        })}
                                        errorObj={errors} name={"email"} ></TextControl>
                                </div>


                                <div className="me-4 mb-2">
                                    <TextControl value={"adf"} lblText={'Password'} type="password"
                                        formReg={register('password', {
                                            required: "Password Required", maxLength: {
                                                value: 20,
                                                message: "Max Length is only 20 char."
                                            }, minLength: {
                                                value: 3,
                                                message: "Atleast minimum 3 char. required"
                                            },
                                            // pattern: {
                                            //     value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                                            //     message: "Password should contain one Capital Letter, one Small Letter and One Special character"
                                            // }
                                        })}
                                        errorObj={errors} name={"password"} >

                                    </TextControl>
                                </div>
                                <label className="d-flex mx-4">
                                    <p className="ms-auto"><Link onClick={() => setScreen("forgetPassword")}>Forget Password</Link></p>
                                </label>

                            </div>


                            <div className="d-flex my-2">
                                <ButtonDark type="submit" className='text-white mx-auto px-5'>Continue</ButtonDark>

                            </div>
                            <label className="d-flex">
                                <p className="mx-auto">Don’t have an account? <Link onClick={() => setScreen("signUp")}>Sign Up</Link></p>
                            </label>
                        </form>
                    </div>}
                {(screen == 'forgetPassword') &&
                    <div className="col-lg-6 py-5 rounded-end">
                        <form onSubmit={handleSubmit(onResetPassword)}>
                            <div className="my-1 ms-4">

                                <h3 className="my-3">Password Reset</h3>

                                <div className="my-2 me-4">
                                    <TextControl value={"sdf@a.com"} lblText={'User Name'}
                                        formReg={register('email', {
                                            required: "Email Required", maxLength: {
                                                value: 50,
                                                message: "Max Length is only 50 char."
                                            }, pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Not a valid Email"
                                            }
                                        })}
                                        errorObj={errors} name={"email"} ></TextControl>

                                </div>

                            </div>


                            <div className="d-flex my-2">
                                <ButtonDark type="submit" className='text-white mx-auto px-5'>Reset Password</ButtonDark>
                            </div>
                        </form>
                        <label className="d-flex">
                            <p className="mx-auto">have an account? <Link onClick={() => setScreen("signIn")}>Sign In</Link></p>
                        </label>
                    </div>}
                {(screen == 'signUpSuccess') &&
                    <div className="col-lg-6 py-5 rounded-end px-0 mx-0">

                        <div className="my-1">
                            <div className="d-flex py-4 my-3">
                                <div className="mx-auto">
                                    <FaCheckCircle size={100} color="green" />
                                </div>

                            </div>
                            <div className="px-4 py-3 d-flex justify-content-center align-items-center">
                                <h4 className="">Account Created</h4>
                            </div>
                            <div className="d-flex text-center">
                                <p className="px-5">Your Account has been Created Successfully. Please SignIn to Use Your Account !</p>
                            </div>
                        </div>
                        <div className="d-flex my-2 mt-3 ">
                            <ButtonDark type="submit" className='text-white mx-auto px-5 my-auto' onClick={() => setScreen("signIn")}>Take me to Sign In</ButtonDark>
                        </div>
                    </div>}
            </div>


        </div >
    </>)
}
export default AuthPage;

