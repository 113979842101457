// components/Loader.js
import React from 'react';
import { useSelector } from 'react-redux';
import './Loader.css'; // Import some basic CSS for the loader
import { WebConfig } from "../WebConfig";

const Loader = () => {
    const isLoading = useSelector(state => state[WebConfig.contextKeys.globalSharedRedux].showPageLoader);

    if (!isLoading) {
        console.log(isLoading, 'load');
        return null;
    }

    return (
        <div className="loader-overlay">
            <div className="loader">Loading...</div>
        </div>
    );
};

export default Loader;
