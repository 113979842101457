import React from 'react';
import Client1 from '../Assets/Client-1.webp'
import Client2 from '../Assets/Client-2.webp'
import Client3 from '../Assets/Client-3.jpg'
import ClientCard from '../Components/ClientCard'

const OurClients = () => {
    return (

        <div className='container-fluid row my-3 '>
            <div className='my-5 row mx-0 px-0'>
                <div className='col-lg-4 col-md-12 col-sm-12 px-5'>
                    <h1 className='my-3'>Our Clients</h1>
                    <p>Since the inception of Bedco Consult Ltd in 2013, the company has been entrusted with planning, running and enhancing the immigration needs of various multinational firms.
                        On our portfolio are the companies listed.</p>
                </div>
                <div className='col-lg-8 col-md-12 col-sm-12 mx-0 px-0'>
                    <div className='row container-fluid mx-0 px-0 justify-content-center justify-content-md-left align-items-center'>
                        <ClientCard img={Client1} title={'KASAPREKO COMPANY LIMITED'} text={'is a leading and successful alcoholic and non - alcoholic beverage producer in Ghana.'} />
                        <ClientCard img={Client2} title={'ROYAL CROWN PACKAGING'} text={'provides a broad range of corrugated box and folding cartons solutions. They supply a variety of standardized and custom cardboard box products to businesses nationwide and across the globe.'} />
                        <ClientCard img={Client3} title={'G&J TECHNICAL SERVICES LIMITED'} text={'is a wholly Ghanaian owned company.They are solely involved in the sale, installation and most importantly the maintenance of Diesel Engine Driven Generating sets.'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurClients;