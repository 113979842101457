import StepsCard from "./StepsCard";

const Steps = () => {
    return (<>
        <section className="col-lg-12 col-md-12 col-sm-12 px-0 mx-0 pb-3" style={{ backgroundColor: "#1c2539" }}>
            <div className="row container-fluid px-0 mx-0 ">
                <div className="text-white px-0 mx-0 ">
                    <div className="d-flex">
                        <h2 className="me-auto ms-3 my-3">Simple Steps and Trustworthy Visa
                        </h2>

                    </div>
                    <div className="d-flex">
                        <p className="me-auto ms-3">Our team of sesaoned professionals understend the complexities of immigration lows and visa
                            prosedures.</p>

                    </div>


                    <div className="row mx-5 text-center d-flex justify-content-center justify-content-sm-center mx-sm-0">

                        <StepsCard num="1" title="Choose Visa Type" text="Determine the visa type for your travel purpose" bgcolor="#05c7c5" />
                        <StepsCard num="2" title="Submit documents" text="Colect all the required document the process" bgcolor="#c7ae05" />
                        <StepsCard num="3" title="Payment Process" text="Process payment as pr the e-visa process" bgcolor="#0a8df2" />
                        <StepsCard num="4" title="E-Visa Delivery" text="Collect your e-visa through out portal" bgcolor="#09af20" />

                    </div>
                </div>
            </div>

        </section>
    </>)

}
export default Steps;