import { useState, useEffect } from "react";
import axios from 'axios'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Controller, useForm } from "react-hook-form";
import { ButtonDark, ErrorControl, ReactModal, TextControl } from "../Components";
import Card from 'react-bootstrap/Card';
import { Typeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { FnHttpService } from "../Utilities";
import { useSelector } from 'react-redux';
import { WebConfig } from "../WebConfig";
import { GlobalSharedReduxFn } from '../Context';
import { useDispatch } from 'react-redux';


const UserManager = () => {
    const userToken = useSelector(state => state[WebConfig.contextKeys.currentUserRedux].authToken);
    const [usersData, setUsersdata] = useState([]);
    const [isModalOpen, setModalState] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const { register, handleSubmit, watch, setValue, reset, control, formState: { errors } } = useForm();
    const { default: classNames } = require("classnames");
    const dispatch = useDispatch();

    const statusOptions = [
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 2 },
        { label: 'Verification Pending', value: 3 }
    ];
    const roles = ['Admin', 'Super Admin', 'User'];


    useEffect(() => {
        getUsersData()
    }, [])

    const getUsersData = async () => {
        debugger;
        console.log(userToken, 'ut');
        dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
        try {
            const response = await axios.get(
                'https://evisa-dev-api.cloud7info.com/api/usermanager',
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }
            );

            if (response.data.data) {
                dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                const data = response.data.data;
                console.log("usersGet", response);
                setUsersdata(data);
            }

            console.log('API response:', response.data);
        } catch (error) {
            console.error('Error:', error.message);
        }
    };


    const submitUserDetails = async (data) => {
        debugger;
        console.log(data, 'user');

        if (data.id) {
            dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
            try {
                const response = await axios.put(
                    `https://evisa-dev-api.cloud7info.com/api/usermanager/changeStatus/${data.id}/${data.status}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`
                        }
                    }
                );

                if (response) {
                    dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                    console.log('API response:', response.data);
                    closeModal();
                    getUsersData();
                }
            } catch (error) {
                console.error('Error:', error.message);
            }
        }
        else {
            console.log(data, 'user');
            let formData = new FormData();
            FnHttpService.toFormData(formData, data);
            dispatch(GlobalSharedReduxFn.pageLoadingStateSet(true));
            try {
                const response = await axios.post('https://evisa-dev-api.cloud7info.com/api/usermanager/addUser', formData, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                });
                console.log('API response:', response.data);
                if (response) {

                    closeModal();
                    dispatch(GlobalSharedReduxFn.pageLoadingStateSet(false));
                }
            } catch (error) {
                console.error('Error:', error.message);
            }
        }

    }

    const changeUserStatus = (userdata) => {
        setIsDisable(true);
        console.log(userdata, 'userdata');
        openFormEditor(userdata);

    }
    const openFormEditor = (item) => {
        console.log(item);
        reset(item || {});
        setModalState(true);
    }
    const closeModal = () => {
        setIsDisable(false);
        reset({});
        setModalState(false);
    }
    // const calApi = ({ baseURL, apiController }) => {
    //     const httpClient = axios.create(
    //         Object.assign({}, {
    //             baseURL: `${baseURL}${apiController}`
    //             , headers: {
    //                 Authorization: `Bearer ${userToken}`
    //             }
    //         })
    //     );

    // }


    return (<>
        {isModalOpen == true && <ReactModal size="md" onModalClose={() => { closeModal() }}>
            <div className="p-3 border-rounded card">
                <form onSubmit={handleSubmit(submitUserDetails)}>
                    <div className="card-header mb-2">
                        <h3>{isDisable ? 'Edit User' : 'Add User'}</h3>
                    </div>
                    <TextControl lblText={'E-mail'}
                        formReg={register('email', {
                            required: "Email Required", maxLength: {
                                value: 50,
                                message: "Max Length is only 50 char."
                            }, pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Not a valid Email"
                            }
                        })}
                        errorObj={errors} name={"email"}
                        disable={isDisable}
                        placeholder="Enter Your Email" ></TextControl>

                    <div className="my-2">
                        <label>Role</label>
                        <div className="my-1">
                            <Controller
                                name="roleName"
                                control={control}
                                defaultValue={null}
                                rules={{ required: "Select role" }}
                                labelKey={(option) => option}
                                render={({ field }) => {
                                    const defaultOption = roles.find(option => option === field.value);
                                    return (
                                        <Typeahead
                                            id="roleTypeahead"
                                            options={roles}
                                            placeholder="Select Role"
                                            onChange={(selected) => {
                                                field.onChange(selected[0]);
                                            }}
                                            defaultSelected={defaultOption ? [defaultOption] : []}
                                            disabled={isDisable}
                                        />
                                    );
                                }}
                            />
                            {errors.roleName && (
                                <p className="text-danger">
                                    {errors.roleName.message}
                                </p>
                            )}
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <TextControl
                                    lblText={'Country Code'}
                                    formReg={register('countryCode', {
                                        required: "Country Code Required",
                                        maxLength: {
                                            value: 5,
                                            message: "Max Length is only 5 char."
                                        }
                                    })}
                                    errorObj={errors}
                                    placeholder="Country code"
                                    name={"countryCode"}
                                    txtboxClass="mb-2"
                                    disable={isDisable}
                                ></TextControl>
                            </div>
                            <div className="col-8">
                                <TextControl
                                    lblText={'Contact Number'}
                                    formReg={register('contactNumber', {
                                        required: "Contact Number Required",
                                        maxLength: {
                                            value: 10,
                                            message: "Max Length is only 10 char."
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "Invalid Contact Number"
                                        }
                                    })}
                                    errorObj={errors}
                                    name={"contactNumber"}
                                    placeholder="Enter contact number"
                                    txtboxClass="mb-2"
                                    disable={isDisable}
                                ></TextControl>
                            </div>
                        </div>
                    </div>
                    <div className="my-2">
                        <label>Status</label>
                        <div className="my-1">
                            <Controller
                                name="status"
                                control={control}
                                rules={{ required: "Select status" }}
                                render={({ field }) => (
                                    <Typeahead
                                        id="statusTypeahead"
                                        options={statusOptions}
                                        placeholder="Select Status"
                                        labelKey="label"
                                        clearButton
                                        onChange={(selected) => {
                                            const selectedValue = selected[0] ? selected[0].value : null;
                                            field.onChange(selectedValue);
                                        }}
                                        defaultSelected={statusOptions.filter(option => option.value === field.value)}

                                    />
                                )}
                            />
                            {errors.status && (
                                <p className="text-danger">
                                    {errors.status.message}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="d-flex">

                        <ButtonDark type="submit" className="text-white mx-auto px-5">Save</ButtonDark>

                    </div>
                </form>
            </div>
        </ReactModal>}
        <div className="d-flex">
            <Button type="button" className="bg-dark border-0 my-2 mx-3 ms-auto" onClick={() => { openFormEditor() }}>Create User</Button>

        </div>
        <Table striped bordered hover className="text-center ">
            <thead>
                <tr>
                    <th>Serial Number</th>
                    <th>Email</th>
                    <th>Role name</th>
                    <th>Contact Number</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
                {usersData.map((x, index) => {
                    return (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{x.email}</td>
                            <td>{x.roleName}</td>
                            <td>{x.contactNumber}</td>
                            <td>{(x?.status === 1) ? "Active" : (x?.status === 2) ? "Inactive" : "Verification Pending"}
                            </td>
                            <td>
                                <Button className="ms-2" variant="outline-success" onClick={() => changeUserStatus(x)}>Change Status</Button></td>
                        </tr>
                    )
                }
                )}
            </tbody>
        </Table>
    </>)
}
export default UserManager;