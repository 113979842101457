import React from 'react';

const ClientCard = ({ img, title, text }) => {
    return (
        <>
            <div className='card mx-3 my-2 m-0 p-0  bg-white' style={{ width: '300px', height: '450px' }}>
                <div className='my-0 py-0  d-flex justify-content-center align-items-center' style={{ width: '100%', height: '250px' }}>
                    <img className="" src={img} />
                </div>
                <div className='card-body h-3  ' style={{ width: '300px', height: '200px' }}>
                    <p><label className='fw-bold'> {title}</label> {text}</p>
                </div>

            </div>

        </>
    );
};

export default ClientCard;